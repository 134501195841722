var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('Breadcrumbs'),_c('v-row',{staticStyle:{"padding":"0px 16px"}},[_c('v-col',{staticStyle:{"padding":"4px 10px"},attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Search Permission","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"5"}}),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.toggleDialog('add')}}},[_vm._v(" add permission ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"12","md":"12","xs":"12","cols":"9"}},[_c('v-row',[_c('v-col',{attrs:{"lg":"12","md":"12","xs":"12","cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","search":_vm.search,"headers":_vm.permissionHeader,"items":_vm.permissionData,"item-key":"id","loading":_vm.loading,"items-per-page":-1,"id":"home-table","hide-default-footer":false,"fixed-header":"","sort-by":"id","header-props":{ sortIcon: null }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""},on:{"click":function($event){return _vm.toggleDialog('add', item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',[_vm._v(_vm._s("Copy as " + item.permission_name))])]),_vm._v(" "+_vm._s("")+" "),_c('v-icon',{attrs:{"dense":""},on:{"click":function($event){return _vm.toggleDialog('update', item)}}},[_vm._v("mdi-pen")]),_vm._v(" "+_vm._s("")+" "),_c('v-icon',{attrs:{"dense":""},on:{"click":function($event){return _vm.deleteConfirmation(item)}}},[_vm._v("mdi-delete")])]}}])})],1),_c('v-dialog',{attrs:{"fullscreen":_vm.dialogPermission.fullscreen,"width":"1200","hide-overlay":true,"persistent":true},model:{value:(_vm.dialogPermission.visible),callback:function ($$v) {_vm.$set(_vm.dialogPermission, "visible", $$v)},expression:"dialogPermission.visible"}},[_c('v-card',{staticClass:"pt-0"},[_c('v-app-bar',{staticStyle:{"background":"rgb(26, 57, 88)"},attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 white--text pl-0"},[_vm._v(_vm._s(_vm.dialogPermission.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.dialogPermission.fullscreen =
                                            !_vm.dialogPermission.fullscreen}}},[_c('v-icon',[_vm._v("mdi-arrow-expand")])],1),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.dialogPermission.visible = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Permission Name","hide-details":""},model:{value:(
                                                _vm.dialogPermission.permission_name
                                            ),callback:function ($$v) {_vm.$set(_vm.dialogPermission, "permission_name", $$v)},expression:"\n                                                dialogPermission.permission_name\n                                            "}})],1),(_vm.dialogPermission.id === -1)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Copy from (optional)","items":_vm.permissionData,"item-text":"permission_name","item-value":"permission","hide-details":""},on:{"change":function (e) { return _vm.changeCopyFrom(e); }}})],1):_vm._e(),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('p',{staticStyle:{"margin":"0"}},[_vm._v("Permission")])]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-switch',{staticStyle:{"margin":"0","padding":"0"},attrs:{"label":_vm.jsonView
                                                    ? 'Toggle UI'
                                                    : 'Toggle Json',"hide-details":""},on:{"click":function () { return _vm.changeView(); }},model:{value:(_vm.jsonView),callback:function ($$v) {_vm.jsonView=$$v},expression:"jsonView"}})],1),(_vm.jsonView)?_c('v-col',{staticClass:"pd-0",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"overflow":"scroll","height":"60vh"}},[(_vm.loadJson)?_c('JsonEditor',{ref:"jsonEditor",model:{value:(
                                                    _vm.dialogPermission.permission
                                                ),callback:function ($$v) {_vm.$set(_vm.dialogPermission, "permission", $$v)},expression:"\n                                                    dialogPermission.permission\n                                                "}}):_vm._e()],1)]):_c('v-col',{staticClass:"pd-0",attrs:{"cols":"12"}},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Book Page ")]),_c('v-expansion-panel-content',[_c('v-row',{staticStyle:{"padding":"0px"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "homeLoginDetailSummary" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "homeLoginDetailSummary" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.homeLoginDetailSummary
                                                                ),callback:function ($$v) {
                                                                    _vm.homeLoginDetailSummary
                                                                =$$v},expression:"\n                                                                    homeLoginDetailSummary\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "mtdVolumeColumn" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "mtdVolumeColumn" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.mtdVolumeColumn
                                                                ),callback:function ($$v) {
                                                                    _vm.mtdVolumeColumn
                                                                =$$v},expression:"\n                                                                    mtdVolumeColumn\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "mtdPnlColumn" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "mtdPnlColumn" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.mtdPnlColumn
                                                                ),callback:function ($$v) {
                                                                    _vm.mtdPnlColumn
                                                                =$$v},expression:"\n                                                                    mtdPnlColumn\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "loginSummaryInfo" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "loginSummaryInfo" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.loginSummaryInfo
                                                                ),callback:function ($$v) {
                                                                    _vm.loginSummaryInfo
                                                                =$$v},expression:"\n                                                                    loginSummaryInfo\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "loginSummaryStatistic" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "loginSummaryStatistic" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.loginSummaryStatistic
                                                                ),callback:function ($$v) {
                                                                    _vm.loginSummaryStatistic
                                                                =$$v},expression:"\n                                                                    loginSummaryStatistic\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "loginSummaryTrades" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "loginSummaryTrades" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.loginSummaryTrades
                                                                ),callback:function ($$v) {
                                                                    _vm.loginSummaryTrades
                                                                =$$v},expression:"\n                                                                    loginSummaryTrades\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "extraSymbolDetails" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "extraSymbolDetails" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.extraSymbolDetails
                                                                ),callback:function ($$v) {
                                                                    _vm.extraSymbolDetails
                                                                =$$v},expression:"\n                                                                    extraSymbolDetails\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "dailySummaryIcon" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "dailySummaryIcon" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.dailySummaryIcon
                                                                ),callback:function ($$v) {
                                                                    _vm.dailySummaryIcon
                                                                =$$v},expression:"\n                                                                    dailySummaryIcon\n                                                                "}})],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" LP Page ")]),_c('v-expansion-panel-content',[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "enableLP" ].label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                    var on = ref.on;
                                                                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                            attrs
                                                                        ,false),
                                                                            on
                                                                        ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "enableLP" ] .tooltip))])])]},proxy:true}]),model:{value:(_vm.enableLP),callback:function ($$v) {_vm.enableLP=$$v},expression:"enableLP"}})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Alert Page ")]),_c('v-expansion-panel-content',[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "enableAlert" ].label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                    var on = ref.on;
                                                                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                            attrs
                                                                        ,false),
                                                                            on
                                                                        ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "enableAlert" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                            _vm.enableAlert
                                                        ),callback:function ($$v) {
                                                            _vm.enableAlert
                                                        =$$v},expression:"\n                                                            enableAlert\n                                                        "}})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Search Page ")]),_c('v-expansion-panel-content',[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "enableSearch" ].label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                    var on = ref.on;
                                                                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                            attrs
                                                                        ,false),
                                                                            on
                                                                        ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "enableSearch" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                            _vm.enableSearch
                                                        ),callback:function ($$v) {
                                                            _vm.enableSearch
                                                        =$$v},expression:"\n                                                            enableSearch\n                                                        "}})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Settings Page ")]),_c('v-expansion-panel-content',[_c('v-row',{staticStyle:{"padding":"0px"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "alertConfig" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "alertConfig" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.alertConfig
                                                                ),callback:function ($$v) {
                                                                    _vm.alertConfig
                                                                =$$v},expression:"\n                                                                    alertConfig\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "brokerConfig" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "brokerConfig" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.brokerConfig
                                                                ),callback:function ($$v) {
                                                                    _vm.brokerConfig
                                                                =$$v},expression:"\n                                                                    brokerConfig\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "serverConfig" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "serverConfig" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.serverConfig
                                                                ),callback:function ($$v) {
                                                                    _vm.serverConfig
                                                                =$$v},expression:"\n                                                                    serverConfig\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "bookConfig" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "bookConfig" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.bookConfig
                                                                ),callback:function ($$v) {
                                                                    _vm.bookConfig
                                                                =$$v},expression:"\n                                                                    bookConfig\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "pkTaker" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "pkTaker" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.pkTaker
                                                                ),callback:function ($$v) {
                                                                    _vm.pkTaker
                                                                =$$v},expression:"\n                                                                    pkTaker\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "routingConfig" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "routingConfig" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.routingConfig
                                                                ),callback:function ($$v) {
                                                                    _vm.routingConfig
                                                                =$$v},expression:"\n                                                                    routingConfig\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "copyTradeConfig" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "copyTradeConfig" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.copyTradeConfig
                                                                ),callback:function ($$v) {
                                                                    _vm.copyTradeConfig
                                                                =$$v},expression:"\n                                                                    copyTradeConfig\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "lpConfig" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "lpConfig" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.lpConfig
                                                                ),callback:function ($$v) {
                                                                    _vm.lpConfig
                                                                =$$v},expression:"\n                                                                    lpConfig\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "notificationConfig" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "notificationConfig" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.notificationConfig
                                                                ),callback:function ($$v) {
                                                                    _vm.notificationConfig
                                                                =$$v},expression:"\n                                                                    notificationConfig\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "alertConfigEdit" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "alertConfigEdit" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.alertConfigEdit
                                                                ),callback:function ($$v) {
                                                                    _vm.alertConfigEdit
                                                                =$$v},expression:"\n                                                                    alertConfigEdit\n                                                                "}})],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Account Menu ")]),_c('v-expansion-panel-content',[_c('v-row',{staticStyle:{"padding":"0px"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "accountPermission" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "accountPermission" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.accountPermission
                                                                ),callback:function ($$v) {
                                                                    _vm.accountPermission
                                                                =$$v},expression:"\n                                                                    accountPermission\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "accountManagement" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "accountManagement" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.accountManagement
                                                                ),callback:function ($$v) {
                                                                    _vm.accountManagement
                                                                =$$v},expression:"\n                                                                    accountManagement\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "accountConfig" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "accountConfig" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.accountConfig
                                                                ),callback:function ($$v) {
                                                                    _vm.accountConfig
                                                                =$$v},expression:"\n                                                                    accountConfig\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "accountProfile" ] .label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                            var on = ref.on;
                                                                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                                    attrs
                                                                                ,false),
                                                                                    on
                                                                                ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "accountProfile" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                                    _vm.accountProfile
                                                                ),callback:function ($$v) {
                                                                    _vm.accountProfile
                                                                =$$v},expression:"\n                                                                    accountProfile\n                                                                "}})],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" K8S Page ")]),_c('v-expansion-panel-content',[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "enableK8s" ].label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                    var on = ref.on;
                                                                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                            attrs
                                                                        ,false),
                                                                            on
                                                                        ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "enableK8s" ] .tooltip))])])]},proxy:true}]),model:{value:(_vm.enableK8s),callback:function ($$v) {_vm.enableK8s=$$v},expression:"enableK8s"}})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Copy Trade Page ")]),_c('v-expansion-panel-content',[_c('v-checkbox',{staticStyle:{"margin":"0","padding":"0"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.permissionSettingInfo[ "enableCopyTrade" ].label)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                    var on = ref.on;
                                                                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",attrs:{"color":"grey","dark":""}},'v-icon',
                                                                            attrs
                                                                        ,false),
                                                                            on
                                                                        ),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.permissionSettingInfo[ "enableCopyTrade" ] .tooltip))])])]},proxy:true}]),model:{value:(
                                                            _vm.enableCopyTrade
                                                        ),callback:function ($$v) {
                                                            _vm.enableCopyTrade
                                                        =$$v},expression:"\n                                                            enableCopyTrade\n                                                        "}})],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":"","disabled":_vm.loading},on:{"click":function($event){_vm.dialogPermission.visible = false}}},[_vm._v("Cancel")]),(
                                        _vm.dialogPermission.type === 'update'
                                    )?_c('v-btn',{attrs:{"color":"green darken-1","text":"","loading":_vm.loading},on:{"click":function($event){return _vm.updatePermission()}}},[_c('span',[_vm._v("Update")])]):_vm._e(),(_vm.dialogPermission.type === 'add')?_c('v-btn',{attrs:{"color":"green darken-1","text":"","loading":_vm.loading},on:{"click":function($event){return _vm.createPermission()}}},[_c('span',[_vm._v("Add")])]):_vm._e()],1)],1)],1)],1)],1)],1)],1),_c('DeleteConfirmDialog',{attrs:{"confirmPopover":_vm.popup,"type":"Permission","name":_vm.selectedDeletion.permission_name,"loading":_vm.loading},on:{"close":function () {
                _vm.popup = false;
            },"confirm":_vm.deleteConfig}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }